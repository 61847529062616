@import url('font-awesome/css/font-awesome.min.css');

:root {
	--primary: #D81E5B;
	--secondary: #8A4EFC;
	--third: rgb(255, 196, 17);
	--light: #EEE;
	--light-alt: #61759B;
	--dark: #131A26;
	--dark-alt: #202b3e;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Tahoma', 'sans-serif';
}

body {
	background-color: var(--dark-alt);
	color: var(--light);
}

.App {
	padding: 32px 0px;
	min-height: calc(100vh - 650px);
	display: flex;
	flex-direction: column;
}

.navbar-collapse {
	flex-grow: unset;
}

h1 {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 32px;
}

h4 {
	font-size: 18px;
	color: var(--light-alt);
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 16px;
}

.icon {
	font-size: 24px;
	margin: 10px;
}

.styled-table {
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 0.9em;
	font-family: sans-serif;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	width: 100%;
}

.styled-table thead tr {
	background-color: #009879;
	color: #ffffff;
	text-align: left;
}

.styled-table th,
.styled-table td {
	padding: 12px 15px;
}

.styled-table tbody tr {
	border-bottom: 1px solid #6b6060;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #1b1a1a;
}

.styled-table tbody tr:last-of-type {
	border-bottom: 2px solid #009879;
}

.fa {
	padding: 3px;
	opacity: 0.1;
}

.fa-good {
	color: rgb(21, 255, 21);
	opacity: 1;
}

.fa-bad {
	color: rgb(255, 21, 21);
	opacity: 1;
}

p.noteAlt {
	background-color: #304a5c;
	border-top: solid 4px #3498db;
	color: rgb(167, 185, 185);
	line-height: 28px;
	overflow: hidden;
	padding: 12px;
}

.chart-ticket-availability {
	color: rgb(255, 196, 17);
}

.chart-price-from {
	color: rgb(255, 99, 132);
}

.chart-buckets {
	color: rgb(178, 128, 251);
}

.chart_main {
	max-width: 100%;
}

.chart {
	max-width: 33%;
	display: inline-block;
	margin: 2px;
}

ul {
	margin: 0px;
	padding: 0px;
}

.footer-section {
	background: #151414;
	position: relative;
}

.footer-cta {
	border-bottom: 1px solid #373636;
}

.single-cta i {
	color: #ff5e14;
	font-size: 30px;
	float: left;
	margin-top: 8px;
}

.cta-text {
	padding-left: 15px;
	display: inline-block;
}

.cta-text h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 2px;
}

.cta-text span {
	color: #757575;
	font-size: 15px;
}

.footer-content {
	position: relative;
	z-index: 2;
}

.footer-pattern img {
	position: absolute;
	top: 0;
	left: 0;
	height: 330px;
	background-size: cover;
	background-position: 100% 100%;
}

.footer-logo {
	margin-bottom: 30px;
}

.footer-logo img {
	max-width: 200px;
}

.footer-text p {
	margin-bottom: 14px;
	font-size: 14px;
	color: #7e7e7e;
	line-height: 28px;
}


.footer-social-icon a {
	color: #fff;
	font-size: 16px;
	margin-right: 15px;
}

.footer-social-icon {
	color: var(--third);
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 38px;
	border-radius: 50%;
	margin-left: 10px;

}

.footer-widget-heading h3 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 40px;
	position: relative;
}

.footer-widget-heading h3::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -15px;
	height: 2px;
	width: 50px;
	background: var(--third);
}

.footer-widget ul li {
	display: inline-block;
	width: 50%;
	margin-bottom: 12px;
}

.footer-widget ul li a:hover {
	color: #ff5e14;
}

.footer-widget ul li a {
	color: #878787;
	text-transform: capitalize;
}

.subscribe-form {
	position: relative;
	overflow: hidden;
}

.subscribe-form input {
	width: 100%;
	padding: 14px 28px;
	background: #2E2E2E;
	border: 1px solid #2E2E2E;
	color: #fff;
}

.footer-subscribe-icon {
	color: var(--secondary);
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 38px;
	border-radius: 50%;
}

.subscribe-form button {
	position: absolute;
	right: 0;
	background: #ffffff;
	padding: 13px 10px;
	border: 1px solid #ffffff;
	top: 0;
}

.subscribe-form button i {
	color: #fff;
	font-size: 22px;
	transform: rotate(-6deg);
}

.copyright-area {
	background: #202020;
	padding: 25px 0;
}

.copyright-text p {
	margin: 0;
	font-size: 14px;
	color: #878787;
}

.copyright-text p a {
	color: #ff5e14;
}

.footer-menu li {
	display: inline-block;
	margin-left: 20px;
}

.footer-menu li:hover a {
	color: #ff5e14;
}

.footer-menu li a {
	font-size: 14px;
	color: #878787;
}

.react-icons {
	color: var(--secondary);
	font-size: 30px;
	float: left;
	margin-top: 8px;
}